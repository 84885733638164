var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.ar(200)),function(r){return _c('div',{key:r,staticClass:"rhyme"},[_c('div',{staticClass:"dot",style:({top: 50+Math.sin(r*3.14/200*2)*55+'vh',
               left:r/2+'%',
               color: _vm.color,
               'z-index': _vm.countZ(r, -1)})},[_vm._v("~ ")]),_c('div',{staticClass:"dot",style:({top: 50-Math.sin(r*3.14/200*2)*55+'vh',
               left:r/2+'%',
               color: _vm.color,
               'z-index': _vm.countZ(r, 1)})},[_vm._v("_ ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }