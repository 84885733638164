import { render, staticRenderFns } from "./Basic.vue?vue&type=template&id=4a724fb8&scoped=true&lang=pug&"
import script from "./Basic.vue?vue&type=script&lang=js&"
export * from "./Basic.vue?vue&type=script&lang=js&"
import style0 from "./Basic.vue?vue&type=style&index=0&id=4a724fb8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a724fb8",
  null
  
)

export default component.exports